import React from 'react'
import { SiMicrosoftexcel } from 'react-icons/si'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
	return <span className="text-4xl">{children}</span>
}

const FileItem = props => {

	const { file, children, names } = props
	const { type, name, size } = file

	const renderThumbnail = () => {
		const isImageFile = type.split('/')[0] === 'image'

		if (isImageFile) {
			return <img
				className="upload-file-image"
				src={URL.createObjectURL(file)}
				alt={`file preview ${name}`}
			/>
		}

		if (type === 'application/zip') {
			return <FileIcon><VscFileZip /></FileIcon>
		}

		if (type === 'application/pdf') {
			return <FileIcon><VscFilePdf /></FileIcon>
		}

		if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
			return <FileIcon><SiMicrosoftexcel /></FileIcon>
		}

		return <FileIcon><VscFile /></FileIcon>
	}

	return (
		<div className="upload-file">
			<div className="flex">
				<div className="upload-file-thumbnail">
					{renderThumbnail()}
				</div>
				<div className="upload-file-info">
					<h6 className="upload-file-name">{name ? name : names}</h6>
					{size ? <span className="upload-file-size">{getKB(size)} kb</span> : null}
				</div>
			</div>
			{children}
		</div>
	)
}

export default FileItem
