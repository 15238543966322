import { get } from "lodash";
import {
  getListCentralBranch,
  getListRegionalBranch,
  getInsuranceByProjectId,
} from "services/ClaimService";
import {
  setCentralBankList,
  setRegionalBankList,
  setInsuranceCompanyList,
} from "store/base/commonSlice";
import { BANK, BANK_CABANG } from "constants/roles.constant";

export const fetchCentralBranch = async ({ dispatch, projectId }) => {
  const response = await getListCentralBranch({
    project_id: projectId,
  });
  const result = get(response, "data.data", []) || [];

  dispatch(setCentralBankList(result));

  return result;
};

export const fetchRegionalBranch = async ({ dispatch, role, projectId }) => {
  const response = await getListRegionalBranch({
    project_id: projectId,
  });
  const result = get(response, "data.data", []) || [];

  if (![BANK, BANK_CABANG].includes(role)) {
    dispatch(setRegionalBankList(result));
  }

  return result;
};

export const fetchInsuranceCompanyList = async ({ dispatch, projectId }) => {
  const response = await getInsuranceByProjectId({
    project_id: projectId,
  });

  const result = get(response, "data.data", []) || [];

  dispatch(setInsuranceCompanyList(result));

  return result;
};

export const generateDefaultParamsByRole = ({ user, role }) => {
  if (![BANK, BANK_CABANG].includes(role)) return {};

  return {
    cabang_id: BANK_CABANG === role ? user.cabang_id : null,
    pusat_id: BANK === role ? user.pusat_id : null,
    id_wilayah: BANK === role ? user.pusat_id : null,
    id_cabang_pusat: BANK_CABANG === role ? user.cabang_id : null,
  };
};

export const getMimeFromExt = (ext) => {
  const mimeTypes = Object.freeze({
    pdf: "application/pdf",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    svg: "image/svg+xml",
    webp: "image/webp",
    ico: "image/x-icon",
    bmp: "image/bmp",
    tiff: "image/tiff",
    tif: "image/tiff",
    txt: "text/plain",
    csv: "text/csv",
    html: "text/html",
    htm: "text/html",
    css: "text/css",
    js: "application/javascript",
    json: "application/json",
    xml: "application/xml",
    zip: "application/zip",
    tar: "application/x-tar",
    rar: "application/vnd.rar",
    mp3: "audio/mpeg",
    wav: "audio/wav",
    ogg: "audio/ogg",
    mp4: "video/mp4",
    avi: "video/x-msvideo",
    mov: "video/quicktime",
    wmv: "video/x-ms-wmv",
    mkv: "video/x-matroska",
    flv: "video/x-flv",
    exe: "application/octet-stream",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    odt: "application/vnd.oasis.opendocument.text",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    epub: "application/epub+zip",
    azw: "application/vnd.amazon.ebook",
    otf: "font/otf",
    ttf: "font/ttf",
    woff: "font/woff",
    woff2: "font/woff2",
    php: "application/x-httpd-php",
    py: "application/x-python-code",
    java: "text/x-java-source",
    sh: "application/x-sh",
    sql: "application/sql",
    rtf: "application/rtf",
    swf: "application/x-shockwave-flash",
    mpeg: "video/mpeg",
    m4v: "video/x-m4v",
    ics: "text/calendar",
  });

  return mimeTypes[ext];
};
