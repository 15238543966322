// import { THEME_ENUM } from "constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
  themeColor: "amber",
  direction: "ltr",
  mode: "light",
  primaryColorLevel: 500,
  cardBordered: true,
  panelExpand: false,
  controlSize: "md",
  navMode: "themed",
  layout: {
    type: "modern",
    sideNavCollapse: false,
  },
  locale: "id",
};
