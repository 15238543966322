import React from "react";
import classNames from "classnames";
import { SIZES } from "../utils/constant";

const Line = (props) => {
  const {
    percent,
    size,
    children,
    strokeColor,
    customBg,
    customProgressClassName,
    insideProgressInfo,
  } = props;

  const progressBackgroundClass = classNames(
    "progress-bg",
    size === SIZES.SM ? "h-1.5" : "h-2",
    customProgressClassName,
    `bg-${strokeColor}`
  );

  const renderInsideProgressInfo = () => {
    if (insideProgressInfo) {
      if (typeof insideProgressInfo !== React.ReactNode) {
        return insideProgressInfo;
      } else {
        return (
          <span className="absolute top-0 bottom-0 text-center w-full text-xs">
            {insideProgressInfo}
          </span>
        );
      }
    }
  };

  return (
    <>
      <div className="progress-wrapper">
        <div className={classNames("progress-inner relative", customBg)}>
          <div
            className={progressBackgroundClass}
            style={{ width: `${percent}%` }}
          />
          {renderInsideProgressInfo()}
        </div>
      </div>
      {children}
    </>
  );
};

export default Line;
